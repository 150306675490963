import Api from './Api';

export function listContracts(supplierId) {
  const exec = `
    query contracts(
      $supplierId: ID!
    ) {
      contracts (
        supplierId: $supplierId
      ) {
        id
        user {
          name
          email
        }
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, { supplierId: supplierId })
  };
}

export function createContract(params) {
  const exec = `
    mutation createContract(
      $supplierId: ID!
      $content: String!
    ) {
      createContract (
        supplierId: $supplierId
        content: $content
      ) {
        id
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}

export function showContract(params) {
  const exec = `
    query contract(
      $supplierId: ID!
      $id: ID!
    ) {
      contract (
        supplierId: $supplierId
        id: $id
      ) {
        id
        content
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}

export function destroyContract(params) {
  const exec = `
    mutation destroyContract(
      $supplierId: ID!
      $id: ID!
    ) {
      destroyContract (
        supplierId: $supplierId
        id: $id
      ) {
        id
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}
