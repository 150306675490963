import Api from './Api';

export function listExperiences(params) {
  const exec = `
    query experiences (
      $supplierId: ID
      $query: String
      $after: String
    ) {
      experiences (
        query: $query
        supplierId: $supplierId
        first: 10
        after: $after
      ) {
        total
        pageInfo {
          startCursor
          endCursor
        }
        edges {
          node {
            id
            slug
            destination
            service
            defaultPrice
            internalTitle
            pricesMode
            pricesValue
            status
          }
        }
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}

export function showExperience(params) {
  const exec = `
    query experience (
      $supplierId: ID
      $id: ID
    ) {
      experience (
        supplierId: $supplierId
        id: $id
      ) {
        id
        slug
        destination
        service
        defaultPrice
        internalTitle
        pricesMode
        pricesValue
        status
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}

export function updateExperience(params) {
  const exec = `
    mutation updateExperience (
      $supplierId: ID
      $id: ID!
      $pricesMode: String
      $pricesValue: Int
    ) {
      updateExperience (
        supplierId: $supplierId
        id: $id
        pricesMode: $pricesMode
        pricesValue: $pricesValue
        transfer: {}
      ) {
        id
        slug
        destination
        service
        defaultPrice
        internalTitle
        pricesMode
        pricesValue
        status
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}

export function publishExperience(params) {
  const exec = `
    mutation updateExperience (
      $id: ID!
      $supplierId: ID
    ) {
      updateExperience (
        id: $id
        supplierId: $supplierId
        published: true
        transfer: {}
      ) {
        id
        published
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}

export function unpublishExperience(params) {
  const exec = `
    mutation updateExperience (
      $id: ID!
      $supplierId: ID
    ) {
      updateExperience (
        id: $id
        supplierId: $supplierId
        published: false
        reviewed: false
        transfer: {}
      ) {
        id
        published
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}
