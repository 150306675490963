import React from 'react';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import { useParams } from "react-router-dom";
import { showOrder } from '../api/Orders';

export default function Order() {
  let { orderId } = useParams();
  const [order, setOrder] = React.useState();

  React.useEffect(() => {
    if (orderId) {
      const { fetcher } = showOrder(orderId);

      fetcher.then(result => {
        setOrder(result.order);
      });
    }
  }, [orderId]);

  if (!order) return <CircularProgress />;

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow hover>
              <TableCell><b>Código</b>: {order.id}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>Checkin</b>: {order.checkin}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>Total</b>: {order.total}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>Usuários</b>: {order.totalAmount}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>Completo</b>: {order.complete ? 'Sim' : 'Não'}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>
                <b>Status</b>:{' '}
                {(!order.confirmed && !order.cancelled) && <Chip label="Pendente" color="default" size="small" />}
                {(order.confirmed && !order.cancelled) && <Chip label="Confirmado" color="primary" size="small" />}
                {order.cancelled && <Chip label="Cancelado" color="secondary" size="small" />}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <br />

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow hover>
              <TableCell><b>Comprador</b>: {order.customer.fullname}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>CPF</b>: {order.customer.cpf}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>Email</b>: {order.customer.email}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>
                <b>Telefone</b>:
                {' '}
                <Link href={`https://wa.me/55${order.customer.phone}`} target="_blank">
                  {order.customer.phone}
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <br />

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow hover>
              <TableCell><b>Produto</b>: {order.experience.publicTitle} ({order.experience.id})</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell><b>Sessão</b>: {order.section.name}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>
                { order.options.map(option => (
                  <div key={option.option.id}>
                    <b>{option.option.name}</b>: {option.amount} x {option.price} ({option.option.details})
                  </div>
                )) }
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>
                <b>Usuários:</b>
                { order.users.map(user => (
                  <div key={user.name}>
                    {user.name}
                  </div>
                )) }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
