import Api from './Api';

export function listCities(uf) {
  const exec = `
    query cities(
      $uf: String!
    ) {
      cities (
        uf: $uf
      ) {
        id
        name
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, { uf: uf })
  };
}
