import { GraphQLClient } from 'graphql-request';

const client = new GraphQLClient(
  process.env.NODE_ENV === 'production'
  ?
  'https://api.parceiro.guiapass.com/graphql'
  :
  'http://localhost:5100/graphql'
);

export default function Api(options = {}) {
  if (options.authorized === true) {
    if (typeof window === 'undefined') return false;
    const user = JSON.parse(window.localStorage.getItem('userData'));
    if (!user || !user.token) return false;
    client.setHeader('authorization', `Bearer ${user.token}`);
  }

  return client;
}
