import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { createSupplier } from '../api/Suppliers';
import { listCities } from '../api/Cities';
import Alert from '../util/Alerts.jsx';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),

    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
    }
  }
}));

export default function NewSupplier() {
  const classes = useStyles();
  const [supplier, setSupplier] = React.useState({});
  const [loading, setLoading] = React.useState();
  const [notice, setNotice] = React.useState();
  const [error, setError] = React.useState();
  const [cities, setCities] = React.useState([]);
  const [services, setServices] = React.useState([]);

  const handleCreateSupplier = () => {
    console.log(supplier);

    setLoading(true);

    const { fetcher } = createSupplier(supplier);

    fetcher.then(result => {
      window.location.href = "/suppliers/" + result.createSupplier.id;
      console.log(result.createSupplier)
    }).catch(err => {
      console.log(err);
      setLoading(false);
      setError('Erro ao salvar');
      setNotice(null);
    });
  };

  const checkService = (service) => {
    if (services.includes(service)) {
      setServices(services.filter(s => s !== service))
    } else {
      setServices([...services, service])
    }
  };

  React.useEffect(() => {
    if (supplier.addressUf) {
      console.log(supplier.addressUf);

      const { fetcher } = listCities(supplier.addressUf);

      fetcher.then(result => {
        setCities(result.cities);
        console.log(result.cities);
      }).catch(err => {
        console.log(err);
      });
    }
  }, [supplier.addressUf]);

  React.useEffect(() => {
    setSupplier({ ...supplier, services: services })
  }, [services]);

  return (
    <>
      <Alert message={notice || error} error={!!error} />

      <Paper className={classes.form}>
        <TextField
          required
          fullWidth
          label="Nome"
          defaultValue={supplier.name}
          onChange={e => setSupplier({ ...supplier, name: e.target.value })}
          variant="outlined"
        />

        <TextField
          required
          fullWidth
          label="CNPJ"
          defaultValue={supplier.cnpj}
          onChange={e => setSupplier({ ...supplier, cnpj: e.target.value })}
          variant="outlined"
        />

        <TextField
          required
          fullWidth
          label="Telefone"
          defaultValue={supplier.phone}
          onChange={e => setSupplier({ ...supplier, phone: e.target.value })}
          variant="outlined"
        />

        <TextField
          required
          fullWidth
          label="Email para convite"
          defaultValue={supplier.invitationEmail}
          onChange={e => setSupplier({ ...supplier, invitationEmail: e.target.value })}
          variant="outlined"
        />

        <TextField
          required
          fullWidth
          label="Razão Social"
          defaultValue={supplier.businessName}
          onChange={e => setSupplier({ ...supplier, businessName: e.target.value })}
          variant="outlined"
        />

        <TextField
          required
          fullWidth
          label="Endereço (rua/av)"
          defaultValue={supplier.addressStreet}
          onChange={e => setSupplier({ ...supplier, addressStreet: e.target.value })}
          variant="outlined"
        />

        <TextField
          required
          fullWidth
          label="Número (endereço)"
          defaultValue={supplier.addressNumber}
          onChange={e => setSupplier({ ...supplier, addressNumber: e.target.value })}
          variant="outlined"
        />

        <TextField
          fullWidth
          label="Complemento (endereço)"
          defaultValue={supplier.addressComplement}
          onChange={e => setSupplier({ ...supplier, addressComplement: e.target.value })}
          variant="outlined"
        />

        <TextField
          required
          fullWidth
          label="CEP (endereço)"
          defaultValue={supplier.addressZipCode}
          onChange={e => setSupplier({ ...supplier, addressZipCode: e.target.value })}
          variant="outlined"
        />

        <TextField
          required
          fullWidth
          label="Bairro (endereço)"
          defaultValue={supplier.addressDistrict}
          onChange={e => setSupplier({ ...supplier, addressDistrict: e.target.value })}
          variant="outlined"
        />

        <TextField
          select
          required
          fullWidth
          label="Estado (endereço)"
          defaultValue={supplier.addressUf || ""}
          onChange={e => setSupplier({ ...supplier, addressUf: e.target.value })}
          variant="outlined"
        >
          <MenuItem value="">-- Selecione</MenuItem>
          <MenuItem value="AC">Acre</MenuItem>
          <MenuItem value="AL">Alagoas</MenuItem>
          <MenuItem value="AP">Amapá</MenuItem>
          <MenuItem value="AM">Amazonas</MenuItem>
          <MenuItem value="BA">Bahia</MenuItem>
          <MenuItem value="CE">Ceara</MenuItem>
          <MenuItem value="DF">Distrito Federal</MenuItem>
          <MenuItem value="ES">Espírito Santo</MenuItem>
          <MenuItem value="GO">Goiás</MenuItem>
          <MenuItem value="MA">Maranhão</MenuItem>
          <MenuItem value="MT">Mato Grosso</MenuItem>
          <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
          <MenuItem value="MG">Minas Gerais</MenuItem>
          <MenuItem value="PA">Pará</MenuItem>
          <MenuItem value="PB">Paraíba</MenuItem>
          <MenuItem value="PR">Paraná</MenuItem>
          <MenuItem value="PE">Pernambuco</MenuItem>
          <MenuItem value="PI">Piauí</MenuItem>
          <MenuItem value="RJ">Rio de Janeiro</MenuItem>
          <MenuItem value="RN">Rio Grande do Norte</MenuItem>
          <MenuItem value="RS">Rio Grande do Sul</MenuItem>
          <MenuItem value="RO">Rondônia</MenuItem>
          <MenuItem value="RR">Roraima</MenuItem>
          <MenuItem value="SC">Santa Catarina</MenuItem>
          <MenuItem value="SP">São Paulo</MenuItem>
          <MenuItem value="SE">Sergipe</MenuItem>
          <MenuItem value="TO">Tocantins</MenuItem>
        </TextField>

        <TextField
          select
          required
          fullWidth
          label="Cidade (endereço)"
          defaultValue={supplier.cityId || ""}
          onChange={e => setSupplier({ ...supplier, cityId: e.target.value })}
          variant="outlined"
        >
          <MenuItem value="">-- Selecione</MenuItem>
          { cities.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>) }
        </TextField>

        <FormControl component="fieldset">
          <FormLabel component="legend">Serviços</FormLabel>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="tour"
              control={<Checkbox color="primary" />}
              label="Tours"
              labelPlacement="end"
              onChange={() => checkService('tour')}
            />
            <FormControlLabel
              value="ticket"
              control={<Checkbox color="primary" />}
              label="Ingressos"
              labelPlacement="end"
              onChange={() => checkService('ticket')}
            />
            <FormControlLabel
              value="transfer"
              control={<Checkbox color="primary" />}
              label="Transfers"
              labelPlacement="end"
              onChange={() => checkService('transfer')}
            />
          </FormGroup>
        </FormControl>

        <Button
          onClick={handleCreateSupplier}
          fullWidth
          variant="contained"
          color="primary"
          size="large"
        >
          Salvar
        </Button>
      </Paper>
    </>
  )
}
