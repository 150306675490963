import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { showContract } from '../api/Contracts';
import Alert from '../util/Alerts.jsx';
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),

    '& > div': {
      margin: '20px auto',
      maxWidth: '800px',
      textAlign: 'justify',
    }
  }
}));

export default function Contract() {
  let { supplierId, id } = useParams();
  const classes = useStyles();
  const [contract, setContract] = React.useState({});
  const [notice, setNotice] = React.useState();
  const [error, setError] = React.useState();

  const handleShowContract = () => {
    const { fetcher } = showContract({
      id: id,
      supplierId: supplierId
    });

    fetcher.then(result => {
      setContract(result.contract);
    }).catch(err => {
      console.log(err);
      setError('Erro ao carregar');
      setNotice(null);
    });
  };

  React.useEffect(() => {
    if (id) {
      handleShowContract();
    }
  }, [id]);

  return (
    <>
      <Alert message={notice || error} error={!!error} />

      {
        contract.content
        &&
        <Paper className={classes.form}>
          <div dangerouslySetInnerHTML={{__html: contract.content.replace(/(?:\r\n|\r|\n)/g, '<br>')}} />
        </Paper>
      }
    </>
  )
}
