import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import { Link } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { listSuppliers } from '../api/Suppliers';

export default function Suppliers() {
  const [suppliers, setSuppliers] = React.useState();

  React.useEffect(() => {
    const { fetcher } = listSuppliers();

    fetcher.then(result => {
      setSuppliers(result.suppliers);
    });
  }, []);

  if (!suppliers) return <CircularProgress />;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Parceiro</TableCell>
            <TableCell align="right">Serviços</TableCell>
            <TableCell align="right">Preço final</TableCell>
            <TableCell align="right">Taxa</TableCell>
            <TableCell align="right">Contrato</TableCell>
            <TableCell align="right">Gerente</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {suppliers.map((row) => (
            <TableRow hover key={row.id}>
              <TableCell component="th" scope="row">
                <Link to={`/suppliers/${row.id}`}>
                  <b>{row.name}</b>
                </Link>
              </TableCell>
              <TableCell align="right">{row.services.join(', ')}</TableCell>
              <TableCell align="right">{row.pricesDefaultMode}</TableCell>
              <TableCell align="right">
                {row.pricesDefaultValue / 1000}
                {row.pricesDefaultMode === 'comission' ? '%' : 'x'}
              </TableCell>
              <TableCell align="right">
                {
                  (row.contract && row.contract !== '')
                  ?
                    <Chip
                      icon={<Icon fontSize="small">check</Icon>}
                      label="Assinado"
                      color="primary"
                      size="small"
                    />
                  :
                    <Chip
                      icon={<Icon fontSize="small">access_time</Icon>}
                      label="Pendente"
                      size="small"
                    />
                }
              </TableCell>
              <TableCell align="right">{row.manager?.name || '-'}</TableCell>
              <TableCell align="right">
                <Button
                  component={Link}
                  to={`/suppliers/${row.id}/contracts`}
                  variant="outlined"
                  size="small"
                  color="primary"
                >
                  Contratos
                </Button>

                &nbsp;

                <Button
                  component={Link}
                  to={`/suppliers/${row.id}/products`}
                  variant="outlined"
                  size="small"
                  color="primary"
                >
                  Produtos
                </Button>

                &nbsp;

                <Button
                  href={`https://parceiro.guiapass.com/authentication/basic/login?sup=${row.id}`}
                  target="_blank"
                  variant="outlined"
                  size="small"
                  color="primary"
                >
                  Link painel
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
