import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import { listExperiences, publishExperience, unpublishExperience } from '../api/Experiences';
import { useParams, Link } from "react-router-dom";

export default function Products() {
  let { supplierId } = useParams();
  const [products, setProducts] = React.useState();
  const [query, setQuery] = React.useState();
  const [page, setPage] = React.useState(0);
  const [pageTo, setPageTo] = React.useState();

  const productServiceSlug = (product) => {
    if (product.service === 'food') {
      return 'restaurantes';
    } else if (product.service === 'transfers') {
      return 'transfers';
    } else {
      return 'atividades';
    }
  };

  const handleQuery = () => {
    let after = null;

    if (pageTo === 'next') {
      after = products.pageInfo.endCursor;
    } else if (pageTo === 'prev') {
      after = (parseInt(products.pageInfo.startCursor) - 10 - 1).toString();
    }

    const { fetcher } = listExperiences({
      supplierId: supplierId,
      query: query?.trim() !== '' ? query : null,
      after: products ? after : null,
    });

    fetcher.then(result => {
      setProducts(result.experiences);
    });
  };

  const handleSearchKeydown = e => {
    if (e.keyCode === 13) {
      handleQuery();
    }
  };

  const handleChangePage = (event, newPage) => {
    if (page < newPage) {
      setPageTo('next');
    } else if (page > newPage) {
      setPageTo('prev');
    } else {
      setPageTo(null);
    }

    setPage(newPage);
  };

  const publishProduct = (productId) => {
    if(!window.confirm('Tem certeza?')) return;

    const { fetcher } = publishExperience({
      id: productId,
      supplierId: supplierId
    });

    fetcher.then(result => {
      if(result.updateExperience.published) {
        window.location.reload();
      }
    });
  };

  const unpublishProduct = (productId) => {
    if(!window.confirm('Tem certeza?')) return;

    const { fetcher } = unpublishExperience({
      id: productId,
      supplierId: supplierId
    });

    fetcher.then(result => {
      if(!result.updateExperience.published) {
        window.location.reload();
      }
    });
  };

  React.useEffect(() => {
    if (supplierId) {
      handleQuery();
    }
  }, [page]);

  if (!products) return <CircularProgress />;

  return (
    <>
      <FormControl fullWidth variant="outlined" size="small">
        <InputLabel htmlFor="search">Busca</InputLabel>
        <OutlinedInput
          id="search"
          type="search"
          defaultValue={query}
          onChange={e => setQuery(e.target.value)}
          onKeyDown={handleSearchKeydown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                size="small"
                aria-label="toggle password visibility"
                onClick={handleQuery}
                edge="end"
              >
                <Icon>search</Icon>
              </IconButton>
            </InputAdornment>
          }
          labelWidth={50}
        />
      </FormControl>

      <br /><br />

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Produto</TableCell>
              <TableCell align="right">Serviço</TableCell>
              <TableCell align="right">Preço final</TableCell>
              <TableCell align="right">Taxa</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Prévia</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.edges.map(n => n.node).map((row) => (
              <TableRow hover key={row.id}>
                <TableCell component="th" scope="row">
                  <b>{row.internalTitle}</b>
                </TableCell>
                <TableCell align="right">{row.service}</TableCell>
                <TableCell align="right">{row.pricesMode}</TableCell>
                <TableCell align="right">
                  {row.pricesValue}
                  {row.pricesMode === 'comission' ? '%' : 'x'}
                </TableCell>
                <TableCell align="right">
                  {
                    (row.status === 'waiting')
                    &&
                    <Chip
                      icon={<Icon fontSize="small">access_time</Icon>}
                      label="Aguardando publicar"
                      color="primary"
                      size="small"
                    />
                  }

                  {
                    (row.status === 'published')
                    &&
                    <Chip
                      icon={<Icon fontSize="small">check</Icon>}
                      label="Publicado"
                      color="primary"
                      size="small"
                    />
                  }

                  {
                    (row.status === 'reviewed')
                    &&
                    <Chip
                      icon={<Icon fontSize="small">eye</Icon>}
                      label="Revisado"
                      color="primary"
                      size="small"
                    />
                  }

                  {
                    (row.status === 'review')
                    &&
                    <Chip
                      icon={<Icon fontSize="small">access_time</Icon>}
                      label="Em revisão"
                      color="secondary"
                      size="small"
                    />
                  }

                  {
                    (row.status === 'draft')
                    &&
                    <Chip
                      icon={<Icon fontSize="small">edit</Icon>}
                      label="Rascunho"
                      size="small"
                    />
                  }
                </TableCell>
                <TableCell align="right">
                  {
                    (row.status === 'waiting')
                    &&
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => publishProduct(row.id)}
                    >
                      Publicar
                    </Button>
                  }

                  &nbsp;

                  {
                    (row.status === 'published')
                    &&
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      onClick={() => unpublishProduct(row.id)}
                    >
                      Despublicar
                    </Button>
                  }

                  &nbsp;

                  <Button
                    component={Link}
                    to={`/suppliers/${supplierId}/products/${row.id}`}
                    variant="outlined"
                    size="small"
                    color="primary"
                  >
                    Editar
                  </Button>

                  &nbsp;

                  <Button
                    href={`https://guiapass.com/${row.destination}/${productServiceSlug(row)}/${row.id}/preview`}
                    target="_blank"
                    variant="outlined"
                    size="small"
                    color="primary"
                  >
                    Pré-visualizar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Paper>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={products.total}
          rowsPerPage={10}
          page={page}
          onChangePage={handleChangePage}
        />
      </Paper>
    </>
  )
}
