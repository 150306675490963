import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { listContracts, destroyContract } from '../api/Contracts';
import { useParams } from "react-router-dom";

export default function Contracts() {
  let { supplierId } = useParams();
  const [contracts, setContracts] = React.useState();

  const handleQuery = () => {
    const { fetcher } = listContracts(supplierId);

    fetcher.then(result => {
      setContracts(result.contracts);
    });
  };

  const handleDestroyContract = (id) => {
    if(!window.confirm('Tem certeza?')) return;

    const { fetcher } = destroyContract({
      supplierId: supplierId,
      id: id
    });

    fetcher.then(result => {
      window.location.reload();
    });  
  };

  React.useEffect(() => {
    if (supplierId) {
      handleQuery();
    }
  }, [supplierId]);

  if (!contracts) return <CircularProgress />;

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Contrato</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Assinatura</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contracts.map((row) => (
              <TableRow hover key={row.id}>
                <TableCell component="th" scope="row">
                  <b>{row.id}</b>
                </TableCell>
                <TableCell>
                  {
                    (row.user)
                    &&
                    <Chip
                      icon={<Icon fontSize="small">check</Icon>}
                      label="Assinado"
                      color="primary"
                      size="small"
                    />
                  }

                  {
                    (!row.user)
                    &&
                    <Chip
                      icon={<Icon fontSize="small">access_time</Icon>}
                      label="Pendente"
                      color="default"
                      size="small"
                    />
                  }
                </TableCell>
                <TableCell>
                  {row.user?.email || '-- Nenhuma'}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    href={`/suppliers/${supplierId}/contracts/${row.id}`}
                  >
                    Abrir
                  </Button>

                  &nbsp;

                  {
                    (!row.user)
                    &&
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      onClick={() => handleDestroyContract(row.id)}
                    >
                      Excluir
                    </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <br/>

      {
        supplierId
        &&
        <Button
          variant="contained"
          color="primary"
          href={`/suppliers/${supplierId}/contracts/new`}
        >
          Novo contrato
        </Button>
      }
    </>
  )
}
