import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createContract } from '../api/Contracts';
import Alert from '../util/Alerts.jsx';
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),

    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
    }
  }
}));

export default function NewContract() {
  let { supplierId } = useParams();
  const classes = useStyles();
  const [contract, setContract] = React.useState({});
  const [loading, setLoading] = React.useState();
  const [notice, setNotice] = React.useState();
  const [error, setError] = React.useState();

  const handleCreateContract = () => {
    setLoading(true);

    const { fetcher } = createContract({
      ...contract,
      supplierId: supplierId
    });

    fetcher.then(result => {
      window.location.href = `/suppliers/${supplierId}/contracts`;
    }).catch(err => {
      console.log(err);
      setLoading(false);
      setError('Erro ao salvar');
      setNotice(null);
    });
  };

  return (
    <>
      <Alert message={notice || error} error={!!error} />

      <Paper className={classes.form}>
        <TextField
          required
          fullWidth
          label="Texto"
          defaultValue={contract.content}
          onChange={e => setContract({ ...contract, content: e.target.value })}
          variant="outlined"
          multiline
          minRows={20}
        />

        <Button
          onClick={handleCreateContract}
          fullWidth
          variant="contained"
          color="primary"
          size="large"
        >
          Salvar
        </Button>
      </Paper>
    </>
  )
}
