import Api from './Api';

export function listOrders(params) {
  const exec = `
    query(
      $query: String
      $first: Int
      $after: String
      $orderBy: String
      $status: String
      $timeline: String
    ) {
      orders (
        orderBy: $orderBy
        first: $first
        after: $after
        query: $query
        status: $status
        timeline: $timeline
      ) {
        total
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            checkin
            externalPackageId
            total
            totalAmount
            complete
            cancelled
            confirmed
            approved
            customer {
              fullname
              cpf
              email
              phone
            }
            section {
              id
              name
            }
            experience {
              id
              internalTitle
              publicTitle
            }
            options {
              amount
              price
              option {
                id
                name
              }
            }
            users {
              name
            }
          }
        }
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, params)
  };
}

export function showOrder(id) {
  const exec = `
    query($id: ID!) {
      order(id: $id) {
        id
        checkin
        externalPackageId
        total
        totalAmount
        complete
        cancelled
        confirmed
        approved
        customer {
          fullname
          cpf
          email
          phone
        }
        section {
          id
          name
        }
        experience {
          id
          internalTitle
          publicTitle
        }
        options {
          amount
          price
          option {
            id
            name
            details
          }
        }
        users {
          name
        }
      }
    }
  `;

  return {
    fetcher: Api({ authorized: true }).request(exec, { id })
  };
}
