import React from 'react';
import Context from './User';

const Provider = ({ children }) => {
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    window.localStorage.removeItem('user');
    const stored = window.localStorage.getItem('userData');
    setUser(stored ? JSON.parse(stored) : {});
  }, []);

  return <Context.Provider value={{ user }}>{children}</Context.Provider>;
};

export default Provider;
