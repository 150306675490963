import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import { listOrders } from '../api/Orders';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  filters: {
    '& .MuiGrid-item': {
      paddingRight: theme.spacing(2),

      '&:last-of-type': {
        padding: 0,
      }
    }
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [pageTo, setPageTo] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [orders, setOrders] = React.useState();
  const [loading, setLoading] = React.useState();
  const [query, setQuery] = React.useState();
  const [status, setStatus] = React.useState('-');
  const [checkin, setCheckin] = React.useState('-');

  const loadOrders = () => {
    setLoading(true);
    let after = null;

    if (pageTo === 'next') {
      after = orders.pageInfo.endCursor;
    } else if (pageTo === 'prev') {
      after = (parseInt(orders.pageInfo.startCursor) - rowsPerPage - 1).toString();
    }

    const { fetcher } = listOrders({
      query: query,
      first: rowsPerPage,
      after: orders ? after : null,
      orderBy: `checkin-${order}`,
      status: status === '-' ? null : status,
      timeline: checkin === '-' ? null : checkin,
    });

    fetcher.then(result => {
      setLoading(false);
      setOrders(result.orders);
    });
  };

  const handleChangePage = (event, newPage) => {
    if (page < newPage) {
      setPageTo('next');
    } else if (page > newPage) {
      setPageTo('prev');
    } else {
      setPageTo(null);
    }

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageTo(null);
  };

  const handleOrder = () => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setPage(0);
    setPageTo(null);
  };

  const handleQuery = () => {
    setPage(0);
    setPageTo(null);
    loadOrders();
  };

  const handleStatus = (value) => {
    setPage(0);
    setPageTo(null);
    setStatus(value);
  };

  const handleCheckin = (value) => {
    setPage(0);
    setPageTo(null);
    setCheckin(value);
  };

  const handleSearchKeydown = e => {
    if (e.keyCode === 13) {
      handleQuery();
    }
  };

  React.useEffect(() => {
    loadOrders();
  }, [page, rowsPerPage, order, status, checkin]);

  if (!orders || loading) return <CircularProgress />;

  return (
    <div className={classes.root}>
      <Grid container className={classes.filters}>
        <Grid item sm={6}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel htmlFor="search">Busca</InputLabel>
            <OutlinedInput
              id="search"
              type="search"
              defaultValue={query}
              onChange={e => setQuery(e.target.value)}
              onKeyDown={handleSearchKeydown}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    aria-label="toggle password visibility"
                    onClick={handleQuery}
                    edge="end"
                  >
                    <Icon>search</Icon>
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={50}
            />
          </FormControl>
        </Grid>

        <Grid item sm={3}>
          <TextField
            select
            fullWidth
            size="small"
            label="Status"
            defaultValue={status}
            onChange={e => handleStatus(e.target.value)}
            SelectProps={{ native: true }}
            variant="outlined"
          >
            <option value="-">-- Todos</option>
            <option value="approved">Pendentes</option>
            <option value="confirmed">Confirmados</option>
            <option value="cancelled">Cancelados</option>
          </TextField>
        </Grid>

        <Grid item sm={3}>
          <TextField
            select
            fullWidth
            size="small"
            label="Checkin"
            defaultValue={checkin}
            onChange={e => handleCheckin(e.target.value)}
            SelectProps={{ native: true }}
            variant="outlined"
          >
            <option value="-">-- Todos</option>
            <option value="future">Futuro</option>
            <option value="past">Passado</option>
          </TextField>
        </Grid>
      </Grid>

      <br />

      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Código</TableCell>

                <TableCell
                  align="left"
                  sortDirection={order}
                >
                  <TableSortLabel
                    active={true}
                    direction={order === 'asc' ? 'desc' : 'asc'}
                    onClick={handleOrder}
                  >
                    Checkin
                  </TableSortLabel>
                </TableCell>

                <TableCell>Cliente</TableCell>
                <TableCell>Serviço</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {orders.edges.map(o => o.node).map(order => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={order.id}
                >
                  <TableCell component="th" scope="row">
                    <RouterLink to={`/orders/${order.id}`}>{order.id}</RouterLink>
                  </TableCell>
                  <TableCell>{order.checkin}</TableCell>
                  <TableCell>{order.customer.fullname}</TableCell>
                  <TableCell>{order.experience.publicTitle}</TableCell>
                  <TableCell>
                    {(!order.confirmed && !order.cancelled) && <Chip label="Pendente" color="default" size="small" />}
                    {(order.confirmed && !order.cancelled) && <Chip label="Confirmado" color="primary" size="small" />}
                    {order.cancelled && <Chip label="Cancelado" color="secondary" size="small" />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={orders.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
