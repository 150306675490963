import Api from './Api';

export function signinUser(params) {
  const exec = `
    mutation signinUser(
      $email: String!
      $password: String!
    ) {
      signinUser(
        email: $email
        password: $password
      ) {
        id
        token
        roles
      }
    }
  `;

  return {
    fetcher: Api().request(exec, params)
  };
}
