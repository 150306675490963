import React from 'react';
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { showExperience, updateExperience } from '../api/Experiences';
import Alert from '../util/Alerts.jsx';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),

    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
    }
  }
}));

export default function Product() {
  const classes = useStyles();
  let { productId, supplierId } = useParams();
  const [product, setProduct] = React.useState();
  const [pricesMode, setPricesMode] = React.useState();
  const [pricesValue, setPricesValue] = React.useState();
  const [loading, setLoading] = React.useState();
  const [notice, setNotice] = React.useState();
  const [error, setError] = React.useState();

  const handleUpdateProduct = () => {
    setLoading(true);

    const { fetcher } = updateExperience({
      supplierId: supplierId,
      id: productId,
      pricesMode: pricesMode || product.pricesMode,
      pricesValue: parseInt(pricesValue || product.pricesValue * 1000),
    });

    fetcher.then(result => {
      setProduct(result.updateExperience);
      setLoading(false);
      setNotice('Atualizado com sucesso');
      setError(null);
    }).catch(err => {
      console.log(err);
      setLoading(false);
      setError('Erro ao salvar');
      setNotice(null);
    });
  };

  React.useEffect(() => {
    if (productId) {
      const { fetcher } = showExperience({
        supplierId: supplierId,
        id: productId
      });

      fetcher.then(result => {
        setProduct(result.experience);
      });
    }
  }, [productId]);

  if (!product || loading) return <CircularProgress />;

  return (
    <>
      <Alert message={notice || error} error={!!error} />

      <h1>{product.internalTitle}</h1>

      <Paper className={classes.form}>
        <TextField
          defaultValue={product.pricesMode}
          onChange={e => setPricesMode(e.target.value)}
          fullWidth
          select
          label="Preço final"
          helperText="Modelo de precificação final (valor padrão)"
          variant="outlined"
        >
          <MenuItem value="markup">Markup</MenuItem>
          <MenuItem value="comission">Comissão</MenuItem>
        </TextField>

        <TextField
          defaultValue={pricesValue || (product.pricesValue * 1000)}
          onChange={e => setPricesValue(e.target.value)}
          fullWidth
          label="Preço final"
          helperText="Exemplo: Markup 1,333 = 1333. Comissão 25% = 25000"
          variant="outlined"
        />

        <Button
          onClick={handleUpdateProduct}
          fullWidth
          variant="contained"
          color="primary"
          size="large"
        >
          Salvar
        </Button>
      </Paper>
    </>
  )
}
